* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: virat;
  src: url(../public/fonts/zacbelx-medium.ttf);
}
::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
