@font-face {
  font-family: virat;
  src: url(../../public/fonts/zacbelx-medium.ttf);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: bwvirat;
  src: url(../../public/fonts/bw/BwGradualDEMO-Bold.ttf);
}

@font-face {
  font-family: bwviratlight;
  src: url(../../public/fonts/bw/BwGradualDEMO-Light.ttf);
}
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap");
.image-container-homepage img {
  width: 100%;
  height: auto;
}
.image-container-homepage {
  position: relative;
  z-index: 0;
  font-family: virat;
}
.homepage-image-text {
  width: 29%;
  font-family: virat;
  position: absolute;
  bottom: 25%;
  left: 5%;
  color: white;
  line-height: 30px;
  font-size: 20px;
}
.homepage-image-text::after {
  content: "";
  position: absolute;
  top: -10%;
  right: 0%;
  /* transform: translateX(-50%); */
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
}
.homapage-headtext {
  padding: 40px 50px;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
}
.homepage-headtext-p {
  font-size: 75px;
  font-family: bwvirat;
}
.herosecbtn {
  margin-top: 20px;
  width: 204px;
  height: 54px;
  background-color: #0e1e41;
  font-size: 20px;
  font-family: bwvirat;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  border-radius: 10px;
  font-weight: bold;
  font-family: virat;
  clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 92% 100%, 0% 100%, 0% 50%);
  cursor: pointer;
  transition: all 1s ease;
}
.herosecbtn1:hover {
  background: #f75825;
  font-size: 15px;
}

.homepage-headtext-button {
  display: flex;
  align-items: center;
  gap: 10px;
}
.homepage-headtext-content {
  text-align: center;
  color: #f75825;
  cursor: pointer;
  display: flex;
  width: 120px;
  align-items: center;
  gap: 3px;
  background-color: white;
  padding: 10px 10px;
  outline: 2px solid #f75825;
}
.circular-button {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #f75825;
  border-radius: 50%;
}
.transpirant .homepage-headtext-content {
  background-color: rgba(255, 255, 255, 0.5) !important;
  outline: 2px solid white;
  color: white;
  outline: 1px solid white;
  width: 140px;
}
.transpirant .circular-button {
  background-color: rgba(255, 255, 255, 0.5);
  outline: 2px solid white;
}
.homepage-headtext-content p {
  font-size: 20px;
  font-weight: bold;
  font-family: virat;
}
.homepage-headtext-icon {
  font-size: 35px;
  color: white;
}

.herodesignwhite {
  background-color: white;
  width: 100%;
  height: 15vh;
  position: absolute;
  bottom: 0;
  clip-path: polygon(80% 0, 100% 80%, 100% 100%, 0 100%, 0 80%);
}

.heroscrollbtn {
  position: absolute;
  bottom: calc(7vh + 10px); /* Align just above the bottom shape */
  left: 80%; /* Position horizontally at the same point as the polygon */
  transform: translateX(-50%); /* Center horizontally */
  width: auto; /* Let the image size be controlled by the img tag */
  z-index: 5; /* Ensure it appears above the polygon */
}

.heroscrollbtn img {
  width: 7vw; /* Adjust image size dynamically for responsiveness */
  /* max-width: 50px; Prevent the image from getting too large */
}

/* stats */
.stats-section {
  display: flex;
  flex-wrap: wrap; /* Enables wrapping for smaller screens */
  justify-content: space-evenly;
  gap: 16px; /* Adds spacing between items */
  padding: 20px;

  .stat-item {
    text-align: center; /* Centers text inside each item */
    flex: 1 1 120px; /* Responsive sizing, adjusts with screen size */
    min-width: 100px; /* Ensures minimum size */
    max-width: 150px; /* Optional maximum size */

    h3 {
      font-size: 32px;
      font-weight: bold;
      color: #000; /* Adjust color as needed */
      margin-bottom: 8px;
      font-family: bwvirat;
    }

    p {
      font-family: virat;
      font-size: 20px;
      color: #252727; /* Adjust color as needed */
    }
  }
}

@media (max-width: 768px) {
  .image-container-homepage {
    height: 60vh;
  }
  .herosecbtn {
    width: 160px;
    height: 40px;
    font-size: 16px;
  }
  .image-container-homepage img {
    height: 100%;
    object-fit: cover;
  }

  .stats-section {
    gap: 12px;
  }

  .stat-item {
    flex: 1 1 100%; /* Each item takes full width on small screens */
    max-width: none;
    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }
  }
  .herodesignwhite,
  .heroscrollbtn {
    display: none;
  }
}

/* ---- About Section Style ---- */

.about-us-section,
.testimonila-section {
  position: relative;
  margin: 40px 0px;
}
.about-us-section .about-background-img,
.testimonila-section .about-background-img {
  width: 100%;
  height: 110vh;
  object-fit: cover;
}
.about-section-content,
.testimonial-section-content {
  position: absolute;
  top: 0;
  left: 0;
}
.about-heading-img {
  height: 40px;
  width: 35px;
}
.about-section-content,
.testimonial-section-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  justify-content: space-between;
}
.about-heading,
.testimonila-heading {
  font-family: virat;
  display: flex;
  align-items: center;
  font-size: 35px;
  color: white;
  gap: 20px;
}
.about-content-part {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 100px;
}
.about-content-heading {
  font-size: 92px;
  font-family: bwvirat;
  color: white;
  width: 35%;
}
.about-content-text {
  margin: -25px -65px;
  padding: 50px;
  font-family: virat;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: rgba(217, 217, 217, 0.1);
  box-shadow: -49.633px 49.633px 49.633px 0px rgba(255, 255, 255, 0.1) inset,
    49.633px -49.633px 49.633px 0px rgba(165, 165, 165, 0.1) inset;
  backdrop-filter: blur(49.63333511352539px);
}
.about-content-text p {
  text-align: left;
  width: 570px;
  font-size: 24px;
  font-style: normal;
  line-height: 38px;
}

/* ----service page style---- */

.our-services-section {
  margin-bottom: 50px;
  padding: 60px 50px 100px 50px;
  display: flex;
  flex-direction: column;
  gap: 100px;
  background-color: #e6f1ff;
}
@media (max-width: 768px) {
  .our-services-section {
    margin-bottom: 50px;
    padding: 60px 5px 100px 30px;
  }
  .our-services-headings-part {
    margin-left: 10px;
  }
  .service-heading img {
    margin-left: 80px;
  }
  .service-heading h2 {
    text-wrap: nowrap;
    font-size: 30px;
  }
  .service-content-heading {
    font-size: 48px;
    margin-left: -20px;
    width: 100% !important;
  }
  .out-service-content {
    width: 100% !important;
  }
}
.our-services-headings-part {
  display: flex;
  align-items: self-start;
}
.our-services-headings-part .service-heading {
  width: 30%;
}

.our-services-headings-part .out-service-content {
  width: 70%;
}
.brand-scroller-head p {
  font-family: bwvirat;
  font-size: 90px;
}
.service-heading {
  padding-top: 20px;
  font-size: 35px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  font-family: bwviratlight;
}
.out-service-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.out-service-content .service-content-heading {
  width: 100%;
  font-size: 64px;
  font-family: bwvirat;
  font-weight: 600;
  margin-top: 0%;
}
.out-service-content p {
  font-family: virat;
  font-weight: 500;
  font-size: 24px;
}
.Serviceecards {
  background-color: #E6F1FF;
  margin: 50px 10px 0px;
  padding: 10px ;
  h1{
    margin: 20px 0 ;
    text-align: center;
    font-size: 64px;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }
}
.services-cards {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  gap: 20px;
  justify-content: center; margin-bottom: 20px;
}
.Serviceecards {
  width: 100%;
  /* height: 700px; */
}
/* partners  */
.service-brand-scroller {
  display: flex;
  background-color: #e6f1ff;
  overflow-x: hidden;
}
.brand-scroller-head {
  font-weight: bold;
  font-family: bwvirat;
  margin: 20px;
  min-width: 20%;
  p {
    font-size: 64px;
  }
}
.brand-line {
  width: 1px;
  z-index: +5;
  background-color: black;
}
/* services cards */
.servcard {
  width: 310px;
  height: 456px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 20px; /* Adjust the corner rounding */
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  position: relative;
  margin: 0 auto;
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}
.card-content {
  width: 100%;
  height: 50px; /* Adjust height of the content container */
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically centers content */
  /* padding: 0 10px; Add some padding */
  box-sizing: border-box;
  z-index: 2; /* Ensure it appears above the background image */
}

.card-content h2 {
  font-family: virat;
  font-size: 20px;
  padding-left: 15px;
  background-color: white;
  color: #333;
  margin: 0; /* Remove default margin */
  height: 100%; /* Allow height to adjust dynamically */
  display: flex;
  align-items: center; /* Vertically center text within its container */
  width: 80%; /* Takes 80% of the width */
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Adds "..." if text overflows */
}

.card-content img {
  width: 20%; /* Adjust size of the icon */
  height: 40px;
  flex-shrink: 0; /* Prevent icon from shrinking */
}
/* how virat works */

.how-virat-works {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
}
.hvw-heading {
  h2 {
    font-family: bwvirat;
    font-size: 64px;
    text-align: center;
  }
}

.process-item {
  text-align: center;
  max-width: 300px;
}

.processcont {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 1rem;
  text-align: left;
}

.step-number {
  background-color: #0e1e41;
  color: #fff;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  font-family: bwvirat;
}

.process-item h3 {
  margin: 10px 0;
  font-size: 28px;
  margin-bottom: 1rem;
  font-family: bwviratlight;
  text-wrap: nowrap;
  text-align: left;
}

.process-item p {
  font-size: 20px;
  font-family: virat;
  text-align: left;
  width: 80%;
  margin-left: 20%;
}
@media (max-width: 768px) {
  .how-virat-works {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
  }
}
@media (min-width: 768px) {
  .casestudy-heading {
    font-size: 48px !important;
    font-family: bwvirat;
  }
  .how-virat-works h2 {
    flex-direction: row;
    justify-content: space-around;
  }

  .process-item {
    max-width: 300px;
  }

  .step-number {
    /* top: -2rem; */
  }
  .brand-scroller-head p {
    font-size: 48px !important;
  }
}
/* ---- Testimonial style ---- */
.testimonial-mobile-view {
  display: none;
}
.testimonial-section-content {
  width: 100%;
  justify-content: flex-start;
  gap: 60px;
}
.testimonila-trust-client {
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
}
.testimonila-trust-client p {
  text-align: left;
  font-family: bwvirat;
  /* width: 50%; */
  font-size: 90px;
  color: white;
  font-weight: bold;
  /* margin-right: 220px; */
  /* margin-top: 100px; */
  position: relative;
}
.testimonial-card-one {
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
  gap: 20px;
}
.testimonial-test {
  color: white;
  width: 500px;
  height: 500px;
}
.testimonial-test p {
  font-size: 25px;
  font-family: virat;
  /* margin-top: -150px; */
}

@media (max-width: 1500px) {
  .brand-scroller-head p {
    font-size: 80px;
  }
  .testimonial-card-one {
    gap: 35px;
    justify-content: flex-end;
  }
  .footer-get-in-touch::after {
    content: "";
    position: absolute;
    bottom: -10%;
    right: 7%;
    width: 45%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.85);
  }
  .values-card-two {
    padding-right: 3.8%;
  }
  .testimonila-trust-client p {
    margin-right: 170px;
    /* margin-top: -82px; */
    font-size: 70px;
  }
  .service-back-img {
    height: 1800px;
  }
}

@media (max-width: 1030px) {
  .about-content-part {
    gap: 20px;
  }
  .about-section-content {
    padding: 40px 15px;
  }
  .about-content-text {
    margin: 0;
  }
  .about-content-text p {
    width: 600px;
  }
  .about-background-img {
    height: 800px;
  }
  .about-content-heading {
    font-size: 50px;
  }
  .service-back-img {
    height: 1400px;
  }
  .heading-card {
    bottom: -75px;
  }
  .our-services-section {
    gap: 160px;
  }
  .brand-scroller-head p {
    font-size: 55px;
  }
  .testimonial-section-content {
    padding: 40px 10px;
  }
  .testimonila-trust-client p {
    margin-right: 130px;
    margin-top: -50px;
    font-size: 70px;
  }
  .testimonial-test p {
    font-size: 21px;
    margin-top: -50px;
  }
}
@media (max-width: 800px) {
  .homepage-headtext-p {
    font-size: 45px;
  }
  .homepage-image-text {
    width: 60%;
    font-size: 17px;
  }
  .about-content-text {
    padding: 30px;
  }
  .about-content-text p {
    width: 500px;
  }
  .out-service-content .service-content-heading {
    font-size: 55px;
  }
  .heading-card {
    bottom: -55px;
    font-size: 15px;
  }
  .brand-scroller-head p {
    font-size: 42px;
  }
  .testimonila-trust-client p {
    margin-right: 10px;
    font-size: 50px;
  }
  .testimonial-test {
    display: none;
  }
  .our-services-section {
    margin: 100px 20px;
  }
  .service-back-img {
    height: 1050px;
  }
  .footer-get-in-touch::after {
    content: "";
    position: absolute;
    bottom: -50%;
    right: 7%;
    width: 45%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.85);
  }
}
@media (max-width: 770px) {
  .image-container-homepage img {
    object-fit: cover;
    height: 600px;
  }
  .homapage-headtext {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .homepage-headtext-p {
    font-size: 40px;
  }
  .about-us-section .about-background-img {
    /* height: 950px; */
    object-fit: cover;
  }
  .about-content-part {
    gap: 80px;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .about-section-content,
  .testimonial-section-content {
    padding: 20px 20px;
  }
  .about-section-content {
    justify-content: space-between;
  }
  .about-content-heading {
    position: relative;
    width: 70%;
    font-size: 48px;
  }
  .about-content-heading::after {
    content: "";
    position: absolute;
    bottom: -10%;
    left: 0%;
    width: 42%;
    height: 2px;
    background-color: white;
  }
  .about-content-text p {
    width: auto;
    font-size: 20px;
    line-height: 30px;
  }
  .about-us-button {
    display: none;
  }
  .transpirant {
    display: none;
  }
  .our-services-headings-part {
    flex-direction: column;
    gap: 30px;
  }
  .our-services-headings-part div {
    width: 100%;
    font-size: 30px;
  }
  .our-services-section {
    gap: 80px;
  }
  .service-brand-scroller {
    flex-direction: column;
    gap: 0px;
  }
  .testimonial-section-content {
    gap: 70px;
  }
  .testimonila-trust-client {
    justify-content: flex-start;
  }
  .testimonila-trust-client p {
    width: 100%;
    font-size: 60px;
  }
  .testimonial-card-one {
    display: none;
  }

  .testimonial-card {
    height: 330px !important;
    width: 396px !important;
    min-width: 290px !important;
    min-height: 396px !important;
    overflow-y: hidden;
  }
  .testimonial-mobile-view {
    display: flex;
    overflow-x: auto;
    gap: 50px;
    /* white-space: nowrap; */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    .testimonila-disc {
      width: 100%;
      font-size: 16px;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  /* Case  */
  .testimonila-section .about-background-img {
    height: 850px;
    object-fit: cover;
  }
  .card-wrapper img {
    max-width: 100%;
    height: auto;
  }
  .card-wrapper {
    height: auto;
  }
  .heading-card {
    bottom: -35px;
    left: 0;
  }
  .services-cards {
    gap: 65px;
  }
}
.wrapper-two {
  /* display: none !important; */
}
.partnerslogoline {
  margin: 0;
  display: flex;
  border-left: 1px solid black;
  flex-direction: column;
}
@media (max-width: 500px) {
  .homapage-headtext {
    padding: 10px;
  }
  .testimonila-section .about-background-img {
    height: 720px;
    object-fit: cover;
  }
  .brand-scroller-head p {
    font-size: 48px;
  }
  .homepage-headtext-p {
    font-size: 36px;
  }
  .about-us-section {
    margin: 30px 0;
  }
  .about-content-heading {
    width: 100%;
  }
  .our-services-section {
    margin: 40px 10px;
  }
  .out-service-content {
    gap: 15px;
  }
  .out-service-content .service-content-heading {
    font-size: 55px;
  }
  .out-service-content p {
    font-size: 15px;
  }
  .brand-scroller-head {
    font-size: 55px;
  }
  .testimonila-section {
    margin: 100px 0;
  }
  .testimonila-trust-client p {
    font-size: 50px;
    margin: 0;
  }
  .testimonial-mobile-view {
    gap: 10px;
    margin: 0 -15px;
  }
  .homepage-image-text {
    width: 90%;
    font-size: 16px;
    line-height: 25px;
  }
  .homepage-image-text::after {
    content: "";
    position: absolute;
    top: -15%;
    left: 22%;
    transform: translateX(-50%);
    width: 50%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .about-heading,
  .testimonila-heading {
    font-size: 30px;
  }
  .wrapper-two {
    display: flex !important;
  }
  .testimonila-disc {
    width: 200px;
  }
}
/* image compare */
/* Responsive Image Comparison Styles */
.image-comparison-container {
  width: 100%;

  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.image-comparison {
  width: 100%;
  max-height: 80vh;
  aspect-ratio: 16 / 9;
}

/* Ensure images are fully responsive */
.image-comparison > div {
  width: 95% !important;
  margin: 0 auto;
  border-radius: 20px;
  height: 100% !important;
}

.image-comparison img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Media Queries for Different Mobile Devices */
/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .image-comparison-container {
    padding: 10px;
  }

  .image-comparison {
    aspect-ratio: 4 / 3;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 577px) and (max-width: 992px) {
  .image-comparison-container {
    padding: 15px;
  }

  .image-comparison {
    aspect-ratio: 4 / 3;
  }
}
/* ---- About Us Style ---- */

.about-wrapper {
  padding: 130px 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.about-section-animation {
  display: flex;
  gap: 20px;
  position: relative;
  justify-content: flex-end;
}
.about-animation-image {
  position: absolute;
  top: -60%;
  left: -25%;
}
.about-animation-image img {
  width: auto;
  height: 1000px;
}
.about-animation-container {
  width: 65%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 580px;
  gap: 100px;
  justify-content: space-evenly;
}
.about-animation-container-ss {
  justify-content: flex-start;
}
.why-us {
  color: #000;
  font-family: bwvirat;
  font-size: 92px;
  font-style: normal;
  font-weight: 700;
  line-height: 118px;
  letter-spacing: -1.012px;
  transition: all 1s ease;
}
.why-us-two {
  color: #000;
  font-family: virat;
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: -0.352px;
  transition: all 1s ease;
}
.why-us-disc {
  color: #000;
  font-family: virat;
  font-size: 24px;
  font-style: normal;
  line-height: 30px;
  letter-spacing: -0.264px;
  opacity: 1;
  transform: all 1s ease;
}
.why-us-disc2 {
  opacity: 0;
  display: none;
  transform: all 1s ease;
}
.why-us-disc2-two {
  color: #000;
  font-family: virat;
  font-size: 24px;
  font-style: normal;
  line-height: 30px;
  letter-spacing: -0.264px;
  opacity: 1;
  transform: all 1s ease;
}
.about-circular-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  font-size: 40px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 1s ease;
}
.about-circular-button:hover {
  background: black;
  color: white;
}
.visible {
  display: flex;
  flex-direction: column;
  gap: 25px;
  opacity: 1;
}

.hidden {
  display: none;
  opacity: 0;
}
.about-container {
  background: #f5f5f5;
  display: flex;
  height: 960px;
  padding-bottom: 60px;
}
.about-vision {
  height: auto;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 62px 20px;
}
.about-vision-heading,
.about-mission-heading {
  font-family: bwvirat;
  color: #000;
  font-size: 92px;
  font-style: normal;
  font-weight: 700;
  line-height: 84px;
  position: relative;
  padding-bottom: 0;
}
.about-mission-heading {
  margin-left: 50px;
}
.about-vision-heading::after {
  content: "";
  position: absolute;
  bottom: -35%;
  left: 56%;
  transform: translateX(-50%);
  width: 80%;
  background: black;
  height: 1px;
}
.about-mission-heading::after {
  content: "";
  position: absolute;
  bottom: -35%;
  left: 26%;
  transform: translateX(-50%);
  width: 80%;
  background: black;
  height: 1px;
}
.about-vision-disc {
  font-size: 24px;
  font-style: normal;
  font-family: virat;
  line-height: 30px;
  width: 350px;
  margin-left: 64px;
}
.about-one-img img {
  width: 573px;
  height: 784px;
}
.about-mission {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.about-mission-disc {
  color: #000;
  width: 425px;
  font-size: 24px;
  font-style: normal;
  font-family: virat;
  line-height: 30px;
}
.about-story-container {
  width: 100%;
  height: 800px;
  background: #f5f5f5;
  display: flex;
  overflow: hidden;
}
.about-story-container div {
  width: 50%;
}
.about-story-content {
  padding: 60px 60px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.story-heading {
  font-family: bwvirat;
  color: #000;
  font-size: 92px;
  font-style: normal;
  font-weight: 700;
  line-height: 84px;
}
.story-disc {
  color: #000;
  font-size: 24px;
  font-style: normal;
  line-height: 30px;
  font-family: virat;
}
.story-image-vertical-moving {
  position: relative;
}
.image-move-heading {
  background: #ff5a36;
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 90;
}
.image-move-heading p {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  line-height: 30px;
}
.moving-div {
  width: 100% !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-content {
  width: 100% !important;
  overflow: hidden;
  position: relative;
}
.inner-slider {
  width: 100% !important;
  gap: 10px;
}
.inner-content .inner-slider {
  -webkit-animation: scroll2 5s linear infinite;
  animation: scroll2 5s linear infinite;
  display: flex;
  flex-direction: column;
  height: calc(100px * 14);
}
@-webkit-keyframes scroll2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100px * 7));
  }
}

@keyframes scroll2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100px * 7));
  }
}
.one-image {
  width: 100% !important;
}
.one-image img {
  width: 100%;
}
.two-image {
  width: 100% !important;
  display: flex;
  gap: 10px;
}
.two-image img {
  width: 50%;
  height: auto;
}
.stroy-scroller-mobile {
  display: none;
}

.about-values-container {
  width: 100%;
  background: #f5f5f5;
  padding: 60px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.about-values-header {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.about-values-hading {
  font-family: bwvirat;
  color: #000;
  font-size: 92px;
  font-style: normal;
  font-weight: 700;
  line-height: 84px;
  position: relative;
}
.about-values-hading::after {
  content: "";
  position: absolute;
  bottom: -40%;
  left: 65%;
  transform: translateX(-50%);
  width: 30%;
  background: black;
  height: 1px;
}
.about-values-disc {
  width: 50%;
  font-family: virat;
  align-self: flex-end;
  color: #000;
  font-size: 24px;
  font-style: normal;
  line-height: 30px;
}
.values-card-one {
  display: flex;
  gap: 30px;
}
.values-card-two {
  padding-right: 9.5%;
  justify-content: flex-end;
}
.cards-value-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.card-values-scroll-container {
  display: none;
}
@media (max-width: 1500px) {
  .about-one-img img {
    width: 490px;
  }
}
@media (max-width: 1030px) {
  .about-animation-image img {
    width: auto;
    height: 730px;
  }
  .why-us {
    font-size: 75px;
  }
  .why-us-disc {
    font-size: 20px;
    line-height: 28px;
  }
  .about-animation-container {
    gap: 0;
  }
  .about-circular-button {
    height: 100px;
    width: 100px;
  }
  .about-vision-heading,
  .about-mission-heading {
    font-size: 70px;
    line-height: 60px;
  }
  .about-vision-disc {
    font-size: 20px;
    line-height: 28px;
    margin-left: 25px;
  }
  .about-mission-disc {
    font-size: 20px;
    margin-left: 25px;
    width: 350px;
  }
  .about-one-img img {
    width: 225px;
  }
  .about-story-container {
    height: 830px;
  }
  .about-story-content {
    gap: 15px;
    padding: 60px 20px;
  }
  .story-heading {
    font-size: 70px;
  }
  .story-disc {
    font-size: 20px;
  }
  .about-values-hading {
    font-size: 70px;
  }
  .about-values-disc {
    font-size: 20px;
    line-height: 28px;
  }
  .about-values-container {
    padding: 60px 10px;
  }
  .values-card-two {
    padding-right: 0;
  }
}
@media (max-width: 1000px) {
  .about-container {
    flex-direction: column;
    height: auto;
    gap: 40px;
  }
  .about-vision-heading,
  .about-mission-heading {
    font-size: 64px;
  }
  .about-vision-heading::after {
    content: "";
    position: absolute;
    bottom: -15%;
    left: 58%;
    transform: translateX(-50%);
    width: 80%;
    background: black;
    height: 1px;
  }
  .about-mission-heading {
    margin-left: 0;
    text-align: right;
    margin-right: 60px;
  }
  .about-mission-heading::after {
    content: "";
    position: absolute;
    bottom: -16%;
    left: 76%;
    transform: translateX(-50%);
    width: 63%;
    background: black;
    height: 1px;
  }
  .about-vision {
    padding: 0 20px;
    width: 100%;
    gap: 20px;
  }
  .about-mission {
    gap: 20px;
    width: 100%;
  }
  .about-vision-disc {
    color: #000;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    width: auto;
  }
  .about-mission-disc {
    color: #000;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    width: 300px;
    align-self: flex-end;
  }
  .about-one-img img {
    height: auto;
    width: 300px;
  }
  .about-story-container {
    flex-direction: column;
    height: auto;
  }
  .about-story-content {
    padding: 40px 10px;
    gap: 15px;
  }
  .about-story-container div {
    width: 100%;
  }
  .story-heading {
    font-size: 64px;
  }
  .story-disc {
    font-size: 14px;
    line-height: 20px;
  }
  .story-image-vertical-moving {
    display: none;
  }
}
@media (max-width: 900px) {
  .cards-value-container {
    display: none;
  }

  .card-values-scroll-container {
    display: flex;
    overflow-x: auto;
    max-width: 100%;
  }

  .card-values-container-mobile {
    display: flex;
    gap: 20px;
  }

  .scroll-buttons {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .scroll-buttons button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin: 0 10px;
  }
  .about-container {
    padding: 60px 0;
  }
  .about-animation-image img {
    width: auto;
    height: 578px;
    margin-top: 80px;
  }
  .about-wrapper {
    gap: 100px;
  }
}

@media (max-width: 500px) {
  .services-cards {
    gap: 60px;
  }
  .about-section-animation {
    gap: 0;
  }
  .about-animation-container {
    width: 100%;
    justify-content: flex-start;
    gap: 70px;
    height: 450px;
  }
  .about-animation-image {
    position: absolute;
    margin-top: -80px;
    top: -3%;
    left: -1%;
  }
  .about-animation-image img {
    width: auto;
    height: 100px;
  }
  .visible {
    gap: 0;
  }
  .why-us {
    font-family: bwvirat;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: -0.704px;
    margin-left: 100px;
  }
  .why-us-disc,
  .why-us-disc2-two {
    color: #000;
    font-family: virat;
    font-size: 16px;
    font-style: normal;
    line-height: 22px;
  }
  .about-circular-button {
    width: 90px;
    height: 90px;
    font-size: 35px;
  }
  .about-wrapper {
    padding: 130px 10px;
  }
  .stroy-scroller-mobile {
    display: flex;
  }
  .about-values-container {
    padding: 40px 10px;
  }
  .about-values-header {
    gap: 25px;
  }
  .about-values-hading {
    font-size: 64px;
  }
  .about-values-hading::after {
    content: "";
    position: absolute;
    bottom: -10%;
    left: 55%;
    transform: translateX(-50%);
    width: 49%;
    background: black;
    height: 1px;
  }
  .about-values-disc {
    width: 70%;
    font-size: 14px;
    line-height: 20px;
  }
  .about-content-text {
    width: 90%;
    padding: 20px;
    margin: 0 auto;
    border-radius: 30px;
  }
}
@media (max-width: 400px) {
  .about-animation-container {
    height: 550px;
  }
}
/* ---- Contact Us ---- */

.contact-container {
  position: relative;
  background-image: url("../assets/footer-mobile.png");
  background-size: cover;
  height: 110vh;
  display: flex;
  padding: 60px 60px;
  padding-top: 100px;
}

.contact-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(30px);
}

.contact-container > div {
  position: relative;
  z-index: 1;
  width: 50%;
}
.contact-first {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact-first div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contact-heading {
  color: #000;
  font-family: bwvirat;
  font-size: 92px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contact-disc {
  color: #000;
  font-family: virat;
  font-size: 24px;
  font-style: normal;
  line-height: 40px;
}
.links-mobile {
  display: flex;
  flex-direction: row !important;
  gap: 20px;
}
.link-mobile {
  background-color: #d9d9d9;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: black;
}
.form-contact {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;
  padding: 0 80px;
}
.form-contact input,
.form-contact textarea {
  background-color: transparent;
  border: none;
  color: black;
  padding-top: 20px;
  font-size: 25px;
  line-height: 40px;
  padding-left: 0;
}
input::placeholder,
textarea::placeholder {
  color: black;
  font-family: virat;
  font-size: 25px;
  font-style: normal;
  line-height: 40px;
}
.form-contact input:focus-visible,
.form-contact input:focus,
.form-contact input:active {
  outline: none !important;
}
.hr-input {
  height: 1px;
  background: #000;
}

/* ---- Human Sustainability ---- */

.sustain-wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 140px 60px;
  padding-bottom: 40px;
}
.heading-sustain {
  display: flex;
  align-items: flex-end;
}
.heading-sustain p {
  width: 50%;
}
.heading-text-sustain {
  color: #000;
  font-family: bwvirat;
  font-size: 92px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.disc-text-sustain {
  color: #000;
  font-family: virat;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.176px;
  margin-bottom: 15px;
  position: relative;
}
.message-input {
  margin-top: 100px;
}
.disc-text-sustain::after {
  content: "";
  position: absolute;
  top: -15%;
  left: 20%;
  transform: translateX(-50%);
  width: 40%;
  height: 1px;
  background-color: #000;
}
.images-sustain {
  display: flex;
  gap: 20px;
}
.images-sustain img {
  width: 50%;
  height: 400px;
  object-fit: cover;
}
.next-part-sustain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sustain-how {
  color: #000;
  font-family: virat;
  font-size: 48px;
  font-style: normal;
  line-height: 50px;
  font-weight: bold;
  letter-spacing: -0.528px;
}
.sustain-circular-button {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  font-size: 40px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 1s ease;
}
.sustain-circular-button:hover {
  background-color: black;
  color: white;
}
.alertContainer {
  position: fixed;
  top: 6%;
  left: 50%;
  transform: translate(-50%, -6%);
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: top 0.5s ease-in-out;
  z-index: 9999;
}
.alertSuccess {
  color: green;
}
.alertError {
  color: red;
  background-color: white;
}
.sustain-main-disc {
  color: #000;
  font-family: virat;
  font-size: 24px;
  font-style: normal;
  line-height: 39px;
  letter-spacing: -0.264px;
}

/* ---- Serivce Page ---- */

.service-page-wrapper {
  position: relative;
  margin-bottom: 0px;
  background-image: url("../assets/2\ 561.png");
  background-size: cover;
  height: 100vh;
  display: flex;
  padding: 10px 60px;
  padding-top: 100px;
}
.service-page-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(15px);
}
.service-page-wrapper > div {
  position: relative;
  z-index: 1;
}
.service-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
}
.hr-service {
  width: 100%;
  height: 1px !important;
}
.service-page-container .service-heading {
  color: white;
  font-family: bwvirat;
  font-size: 92px;
  font-style: normal;
  font-weight: 700;
  line-height: 119px;
  letter-spacing: -1.012px;
}
.service-details-container {
  height: 600px;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  gap: 70px;
}
.service-details-container::-webkit-scrollbar {
  width: 0.5em;
}
.service-details-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.service-details-container {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.serivce-details {
  width: 60%;
  display: flex;
}
.serivce-details img {
  height: 400px;
  width: 300px;
  object-fit: cover;
  filter: grayscale(100%);
}
.details-service {
  background: rgba(0, 0, 0, 0.32);
  box-shadow: 4px 4px 74.8px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(78px);
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: -50px;
  margin-top: 150px;
}
.details-service h1 {
  text-transform: uppercase;
  font-family: virat;
  font-weight: bold;
  color: white;
}
.details-service p {
  color: white;
  font-family: virat;
}
.circular-cirle-white {
  position: absolute !important;
  bottom: 5%;
  left: 2%;
  border: 1px solid white;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-size: 70px;
  color: white;
  justify-content: center;
  transition: all 0.5s ease;
}
.circular-cirle-white:hover {
  border-color: black;
  background: black;
}
.links-mobile2 {
  display: none !important;
}
@media (max-width: 1030px) {
  .heading-text-sustain {
    font-size: 65px;
  }
}
@media (max-width: 800px) {
  .contact-container {
    flex-direction: column;
    height: auto;
    gap: 30px;
    padding: 130px 20px;
    position: relative;
  }
  .contact-container > div {
    width: 100%;
  }
  .form-contact {
    align-items: flex-start;
    padding: 0;
  }
  .contact-first div {
    align-items: center;
    text-align: center;
  }
  .contact-heading {
    font-size: 40px;
  }
  .contact-disc {
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
  }
  .contact-first .links-mobile {
    display: none !important;
  }
  .links-mobile2 {
    width: auto !important;
    display: flex !important;
    position: absolute !important;
    bottom: 20px;
    gap: 10px;
  }
  .form-contact input,
  .form-contact textarea {
    font-size: 18px;
  }
  .input-contact {
    width: 100%;
  }
  .sustain-wrapper {
    padding: 100px 10px;
  }
  .heading-sustain {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
  .heading-sustain p {
    width: 100%;
  }
  .heading-text-sustain {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: -0.352px;
  }
  .disc-text-sustain::after {
    content: "";
    position: absolute;
    top: -15%;
    left: 20%;
    width: 0;
    transform: translateX(-50%);
    height: 1px;
    background-color: #000;
  }
  .disc-text-sustain {
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.176px;
  }
  .images-sustain {
    flex-direction: column;
  }
  .images-sustain img {
    width: 100%;
  }
  .sustain-how {
    font-size: 32px;
    font-style: normal;
    line-height: 50px; /* 156.25% */
    letter-spacing: -0.352px;
  }
  .sustain-circular-button {
    height: 100px;
    width: 120px;
  }
  .sustain-main-disc p {
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 150% */
    letter-spacing: -0.176px;
  }
}
.services-page-mobile {
  display: none;
}
@media (max-width: 800px) {
  .service-page-wrapper {
    display: none;
  }
  .services-page-mobile {
    display: flex;
    padding: 120px 10px;
    flex-direction: column;
    gap: 40px;
  }
  .service-container-card {
    display: flex;
    flex-direction: column;
    gap: 30px;
    background: #f3f3f3;
    transition: all 0.5s ease;
  }
  .service-container-card p {
    padding: 0 20px;
  }
  .service-container-card img {
    width: 100%;
  }
  .head-card-serivce {
    color: #000;
    font-family: virat;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 29.869px;
    letter-spacing: -0.308px;
  }
  .disc-card-serivce {
    color: #000;
    font-family: virat;
    font-size: 16px;
    font-style: normal;
    transition: all 0.5s ease;
    line-height: 24px;
  }
  .read-more {
    color: #ff5a36;
    font-family: virat;
    font-size: 20px;
    font-style: normal;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
@media (max-width: 1030px) {
  .circular-cirle-white {
    height: 120px;
    width: 120px;
  }
}
@media (max-width: 400px) {
  .about-us-section .about-background-img {
    height: 1000px;
    object-fit: cover;
  }
  .card-wrapper img {
    max-width: 100%;
  }
  .heading-card {
    left: 0;
  }
  .footer-mobile-heading {
    font-size: 30px;
    padding-left: 20px;
  }
}
@media (max-width: 370px) {
  .testimonila-section .about-background-img {
    height: 820px;
  }
  .testimonila-disc {
    width: 200px;
  }
  .about-us-section .about-background-img {
    height: 1200px;
    object-fit: cover;
  }
  .about-content-heading {
    font-size: 60px;
  }
}

.services-background img {
  height: 600px;
  object-fit: cover;
}
.service-on-text {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  bottom: 30%;
  left: 5%;
  font-weight: bold;text-align: center;
}
.service-on-text h1 {
  font-family: bwvirat;
  /* margin-left: 30px; */
  font-size: 64px;
  font-weight: 700;
  line-height: 84px;
  text-align: left;
  color: white;
  text-wrap: nowrap;
}

.service-on-text p {
  width: 50% !important;
   margin: 0 ;

  text-align: left !important;
  color: white;
  font-size: 20px;
  font-family: virat;
  font-weight: normal;
  
}

.services-first-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 40px; 

} .headmargindiv{
 font-size: 60px !important;
 line-height: 60px !important;
}
.services-first-box h1 {
  font-family: bwvirat;
  font-size: 48px;
  margin-bottom: 20px;
}
.services-first-box .text-p2 {
  max-width: 95%;   font-family: virat;
  font-size: 16px; text-align: justify;
}
.text-p {
  font-family: virat;
  font-size: 16px;
  width: 95%;
  text-decoration: none;
  a{
    text-decoration: none;
  font-family: virat;
  font-size: 20px;
  font-weight: normal; 
  }
}
.services-first-box .text-h2 {
  font-family: bwvirat;
  font-size: 32px;
  margin: 20px 0;
}
.services-first-box .image-content {
  height: 100%!important;
  object-fit: cover;
} .services-first-box .image-content {
       /* Ensure the image container takes up full width */
  min-height: 100% !important;       /* Ensure the image container takes up full height */

}
.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.whatsapp-icon a img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.whatsapp-icon a img:hover {
  transform: scale(1.1);
}


.service-cards {
  padding: 80px 40px;
 
  justify-content: space-between !important;
}
.service-cards .vlaues-card-container {
  background: linear-gradient(
    331.87deg,
    rgba(108, 26, 0, 0.44) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  max-height: 540px;
  max-width: 425px;
  padding: 10px 15px;  border-radius: 30px;
}
.services-details .values-card-two {
  padding-right: 40px !important;
} .vlaues-card-container img{width: 100px;
height: 100px; object-fit: contain;}
.service-cards .values-card-name {
  font-family: virat;
  font-size: 24px; color: #252727;
  line-height: 62.92px;
  font-weight: bold;
}
.service-cards .values-card-disc {
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  text-align: left;
  color: #000;
}
.image-content .second-image {
  width: 300px;
  height: 350px;
  object-fit: contain;
}
.services-bottom-disc {
  padding: 100px 40px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.servicesdisctxt{
  display: flex;
  flex-direction: column;
  p{  font-size: 20px;
  text-decoration: none
  ;}
}
.services-bottom-disc h1 {
  font-family: bwvirat;
  font-size: 48px;
  line-height: 50px;
  margin-bottom: 50px;
  margin: 20px 0 30px 0;
}
.services-bottom-disc p {

  font-family: virat;
  font-size: 22px;
  text-decoration: none;
  width: 80%;
  line-height: 130%;
  margin: 0 auto;
  a{
    /* color: #ff5a36; */
    font-size: 20px;font-weight: normal;
    text-decoration: none;
  }
  
}

.service-only-two {
  justify-content: start !important;
  gap: 82px;
}

@media (max-width: 1050px) {
  .service-on-text h1 {
    font-size: 45px;
    line-height: 55px;
  }
  .service-on-text p {
    font-size: 13px;
  }

  .services-first-box {
    padding: 40px 10px;
  }
  .image-content img {
    width: 300px;
  }
  .services-first-box h1 {
    font-size: 26px;
  }
  .services-first-box .text-h2 {
    font-size: 24px;
  }
  .text-p,
  .services-first-box .text-p2 {
    width: 100%;
    font-size: 14px; text-align: justify; max-width: 100%;
  font-family: virat;  a{
    text-decoration: none;
  font-family: virat;
  font-size: 14px;
  font-weight: normal; 
  } }
  .service-cards {
    padding: 80px 10px;
  }
  .service-cards .vlaues-card-container img {
    width: 60px;
    height: 60px;
  }
  .service-cards .values-card-name {
    font-size: 29px;
    line-height: 40.92px;
    margin-bottom: 25px;
  }
  .service-cards .values-card-disc {
    font-size: 14px;
  }
  .services-details .values-card-two {
    flex-direction: column;
    align-items: center;
    padding: 0px 10px;
  }
  .service-only-two {
    gap: 30px;
  }
  .image-content .second-image {
    width: 300px;
  }
  .services-bottom-disc {
    padding: 60px 15px;
  }
  .services-bottom-disc h1 {
    font-size: 33px;
    line-height: 45px;
    margin-bottom: 20px;
  }
  .services-bottom-disc p {
    font-size: 18px;
    text-decoration: none;
    width: 100%;
    text-align: justify;
    font-family: virat;
  }
}

.serivce-two .change-2 {
  width: 1010px;
}
.serivce-two p {
  width: 1000px;
}
.image-content .service-2-img {
  width: 420px;
}
.service-two-cards .vlaues-card-container .values-card-name {
  display: none;
}

.service-two-cards .vlaues-card-container {
  gap: 75px;
}
.services-bottom-disc-2 {
  padding: 50px 40px;
}
.services-bottom-disc-2 p {
  font-size: 18px;     text-decoration: none;
}

@media (max-width: 1050px) {
  .serivce-two .change-2 {
    width: 650px;
  }
  .serivce-two {
    left: 3%;
  }
  .serivce-two p {
    width: 650px;
  }
}
@media (max-width: 700px) {
  .services-bottom-disc img{
    display: none;
  }
  .serivce-two .change-2 {
    width: 520px;
    font-size: 35px;
    line-height: 40px;
  }
  .serivce-two {
    left: 3%;
  }
  .serivce-two p {
    width: 500px;
  }
  .image-content .service-2-img {
    width: 270px;
  }
}

@media (max-width: 500px) {
  .image-container-homepage img {
    height: 100%;
  }
  .service-on-text h1 {
    width: 80%;
    font-size: 32px;
    margin-left: 0;
    line-height: 1.2;
  }
  .service-on-text {
    width: 100%;
    bottom: 10px;
    left: 0;
line-height: 110%;
    gap: 10px;
    padding: 0 10px;
  }
  .service-on-text p {
    font-size: 16px;
    width: 100% !important;
    text-align: justify !important;
  }
  .services-first-box {
    padding: 20px 10px;
    flex-direction: column;
    gap: 30px;
  }
  .image-content img,
  .image-content .second-image {
    width: 100%;
  }
  .services-bottom-disc h1 {
    font-size: 36px;
    line-height: 30px;
  }
  .service-cards-wrapper {
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px 0;
  }

  .values-card-one,
  .values-card-two {
    flex: 0 0 auto;
    width: 80%;
    max-width: 300px;
  }

  .services-details .values-card-two {
    flex-direction: row;
  }

  .service-cards-wrapper::-webkit-scrollbar {
    display: none;
  }

  .service-cards-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .service-cards .vlaues-card-container {
    padding: 15px;
    min-height: 350px;
  }
  .service-cards .values-card-name {
    font-size: 20px;
    line-height: 30px;
  }
  .service-cards .values-card-disc {
    font-size: 14px;
    line-height: 16px; text-align: justify;
  }
  .serivce-two .change-2 {
    width: 80%;
    font-size: 32px !important;
    line-height: 32px !important;
  }
  .serivce-two p {
    width: 100%;
  }

  .service-two-cards .values-card-disc {
    width: 230px;
  }
  .services-first-box h1 {
    font-size: 36px;
  }
  .image-content .service-2-img {
    width: 100%;
  }
  .services-bottom-disc-2 {
    padding: 50px 10px;
  }
  .services-bottom-disc-2 p {
    font-size: 14px;
  }
  .casestudy-heading h2 {
    width: 100%;
    font-size: 48px;
    font-family: bwvirat;
    font-weight: 600;
    margin: 50px 0 0px 0;
    text-align: left;
  }
  .casestudy_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 330px !important;
    height: 405px !important;
    transition: all 0.3s ease;
    border-radius: 10px;
    overflow: hidden;
    /* background: #fff; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .casestudy_image_container {
    width: 100%;
    height: 405px !important; /* Set a fixed height for consistency */
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px 10px 0 0; /* Rounded corners for the top */
  }
}

.links-on-text {
  text-decoration: underline;
  font-size: 15px;
  font-weight: 900;
  color: black;
}

/* Case Study */ /* Container styles */
.casestudy_container {
  width: 95%;
  padding: 20px;
  text-align: center;
  margin-left: 50px;
}

/* Swiper styles */
.casestudy_swiper {
  width: 100%;
  padding: 20px 0;
  /* padding-left: 50px; */
}
.casestudy-heading {
  width: 100%;
  font-size: 64px;
  font-family: bwvirat;
  font-weight: 600;
  margin: 50px 0 30px 0;
  text-align: left;
}
/* Card styles */
.casestudy_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px !important;
  height: 506px;
  transition: all 0.3s ease;
  border-radius: 10px;
  overflow: hidden;
  /* background: #fff; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.casestudy_card:hover {
  /* width: 310px; */
  /* transform: scale(1.1); */
}

/* Image container */
.casestudy_image_container {
  width: 100%;
  height: 506px; /* Set a fixed height for consistency */
  overflow: hidden;
  border-radius: 10px 10px 0 0; /* Rounded corners for the top */
}

.casestudy_image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
  transition: transform 0.3s ease;
}

.casestudy_card:hover .casestudy_image_container img {
  transform: scale(1.1); /* Zoom effect on hover */
}

/* Text styles */
.casestudy_title {
  position: absolute;
  bottom: 20px;
  left: 5%;
  /* transform: translateX(-50%); */
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
  color: #ffffff;
  font-family: virat;
  text-align: left;
}

/* See More button */
.casestudy_see_more {
  margin-top: 20px;
  background: #007bff;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 20px;
  width: 204px;
  height: 54px;
  background-color: #0e1e41;
  font-size: 20px;
  font-family: bwvirat;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  border-radius: 10px;
  font-weight: bold;
  font-family: virat;
  clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 92% 100%, 0% 100%, 0% 50%);
  cursor: pointer;
  transition: all 1s ease;
  position: relative;
  margin-left: 40%;
  /* transform: translateX(-50%); */
}

.casestudy_see_more:hover {
  background: #0056b3;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .casestudy_card {
    width: 180px;
  }
  .casestudy_container {
    width: 95%;
    padding: 0px;
    text-align: center;
    margin-left: 10px;
  }

  .casestudy_image_container {
    height: 150px;
  }

  .casestudy_see_more {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
.firsttxtimg {
       /* Stretch the image to fill the container width */
  height: 100%; 
  margin: 0;
  min-height: 100%;       /* Stretch the image to fill the container height */
  object-fit: fill;  /* Ensure the image maintains aspect ratio and covers the entire container */
}


.contactSection {
  position: relative;
  width: 100%;
  height: 400px;

  @media (max-width: 768px) {
    height: 600px;
  }

  .mapContainer {
    width: 100%;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .contactCard {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    max-width: 900px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 10px;
    }

    .contactItem {
      display: flex;
      align-items: center;
      margin: 10px 20px;

      @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
      }

      .icon {
        font-size: 24px;
        margin-right: 10px;

        @media (max-width: 768px) {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }

      h3 {
        font-size: 18px;
        font-weight: bold;
      }

      p {
        font-size: 16px;
        color: #555;
      }
    }
  }
}


a{
  /* color: #007bff; */
  text-decoration: none;
}

/* map in contact us page */
.map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 20px auto;

  background: #f9f9f9;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .responsive-map {
    width: 100%;
    height: 300px;
    border: none;
    border-radius: 8px;
  }

  .map-info {
    margin-top: 10px;
    text-align: center;

    h4 {
      font-size: 1.2rem;
      color: #333;
    }

    p {
      font-size: 0.9rem;
      color: #555;
    }

    a {
      font-size: 0.9rem;
      color: #007bff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .map-container {
    width: 90%;
    padding: 10px;

    .responsive-map {
      height: 250px;
    }
  }
}


.contact-discq{
  color: #000;
  font-family: virat;
  font-size: 20px;
  
}