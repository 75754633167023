.navbar-container {
  position: relative;
  z-index: 100;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: virat;
  src: url(../../public/fonts/zacbelx-extrabold.ttf);
}

@font-face {
  font-family: bwvirat;
  src: url(../../public/fonts/bw/BwGradualDEMO-Bold.ttf);
}
.navbar-menu {
  margin-top: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 95%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 10px);
  display: flex;
  align-items: center;
  gap: 10px;
  color: black;
  font-family: virat;
  font-weight: bold;
  border: 0.5px solid #afafaf;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.06) 0.02%,
    rgba(255, 255, 255, 0) 109.38%
  );
  box-shadow: -57.833px 57.833px 57.833px 0px rgba(255, 255, 255, 0.14) inset,
    57.833px -57.833px 57.833px 0px rgba(194, 194, 194, 0.14) inset;
  backdrop-filter: blur(56.676666259765625px);
}
.navbar-menu div {
  display: flex;
  gap: 10px;
}
.navbar-menu p {
  cursor: pointer;
  z-index: 200;
  opacity: 1;
  color: #ffffff;
  transition: opacity 0.3s ease;
}
.logo {
  width: 142.222px;
  height: 80px;
  flex-shrink: 0;
  cursor: pointer;
}
.navbar-menu p.hidden {
  opacity: 0;
}
.navbar-menu::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 0.1px;
  background-color: #afafaf;
}
.navbarContactdetail {
  padding: 5px;

  h2 {
    color: #ffffff;
    font-size: 18px;
    font-weight: lighter;
    margin: auto;
    cursor: pointer;
  }
}
@media (max-width: 750px) {
  .navbar-menu .navbarContactdetail {
    display: none;
  }
}
.navbar-icon {
  font-size: 25px;
  color: #fff;
}
.navbar-icon-open {
  font-size: 20px;
  cursor: pointer;
  z-index: 200;
  color: #ffffff;
  transition: transform 0.3s ease;
}
.navbar-icon {
  font-size: 20px;
  text-align: center;
  justify-content: center;
  margin: auto;
}
.rotate {
  transform: rotate(180deg);
}
.drawer-overlay {
  position: fixed;
  max-width: 500px;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    332deg,
    rgba(108, 26, 0, 0.44) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  backdrop-filter: blur(77.42933654785156px);
  display: flex;
  z-index: -1;
  opacity: 0;
  transition: left 0.5s ease, opacity 0.5s ease;
}

.drawer-content {
  position: relative;
  transition: transform 0.5s ease, opacity 0.5s ease;
  padding: 110px 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  gap: 20px;
  overflow-y: scroll;
}
.drawer-content a {
  font-family: virat;
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  line-height: 18.548px;
  letter-spacing: -0.528px;
  opacity: 0.55;
  display: flex;
  gap: 40px;
}
.drawer-content a p {
  margin-top: 20px;
}.selected {

  opacity: 1 !important;

}
.selectedtabb {
  background-color:#F6C7BA;
width: auto !important;
/* margin: 0 10PX; */
border-radius: 20PX;
  opacity: 1 !important;
  P{
    color:#B93B11 ;

  }
}
.drawer-overlay.open {
  opacity: 1;
  right: 0;
}

.drawer-content.open {
  opacity: 1;
}

.line-indication {
  height: 200%;
  width: 2px;
  background: rgba(255, 255, 255, 0.7);
}
.selected-line {
  background-color: #fff !important;
}

.services_tabdiv{
  
  background: #1d1c1c31;
/* height: auto; */
}

.servicesubtab{
  margin-left: 20px;
  background: #ffffff31;
  border-radius: 20px;
  /* padding-right: 20px; */
  padding-left: 0px;
   padding-bottom: 10px;
/* padding-top: 10px; */
  a{
    width: auto;    gap: 0px;
    padding: 10px 20px ;
    margin-top: 10px;
    /* margin: auto; */
    p{ text-align: left;
      margin: 5px 0px;
      padding-left: 20px;
      
      width: 100%;
      
    }
    :active{
      color: #B93B11;
      background-color: #F6C7BA;
    }
  }
a:hover{
  /* margin: auto; */
  /* height: 100%; */
  /* margin-top: 10px; */
  background-color: #F6C7BA;
  border-radius: 20px;
}

}
.servicestab{

 
  margin: 0;
  padding: 0;
  text-wrap: none;
  border-left: 2px solid white;
  margin-bottom: -20px ;
  margin-top: -20px;
  padding: 10px;
  border-bottom-right-radius: 10px;


}

.svgg{
  width: 20px;
color: white;
margin-bottom: -2px;
margin: 0;
padding: 0;

} .svgg1{
  width: 20px;
color: white;


margin: 0;
margin-left: -30px;
height: 50%;
margin-top: 12%;
padding: 0;

}
/* ---- card style ---- */
.card-container-service {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  transition: margin-right 0.5s ease;
}
.card-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.5s ease;
}
.card-wrapper img {
  max-width: 450px;
  height: 500px;
  object-fit: cover;
}
.card-sidebar {
  position: absolute;
  right: -108%;
  bottom: -100%;
  z-index: 97;
  height: 100%;
  width: 480px;
  transition: all 1s ease;
  background-color: white;
  opacity: 0;
}
.card-sidebar3 {
  position: absolute;
  left: -108%;
  bottom: -100%;
  z-index: 96;
  height: 100%;
  width: 480px;
  transition: all 1s ease;
  display: flex;
  background-color: white;
  opacity: 0;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}
.heading-card {
  position: absolute;
  font-family: virat;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  bottom: -40px;
  left: 0;
  background-color: white;
}
.card-disc {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  padding: 5px;
  justify-content: flex-end;
  height: 100%;
}
.heading-card2 {
  font-family: virat;
  font-size: 20px;
  font-weight: bold;
}
.disc-card {
  font-family: virat;
}
.card-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  align-items: center;
  justify-content: center;
  height: 180px;
  font-size: 80px;
  width: 180px;
  border: 2px solid white;
  color: white;
  border-radius: 50%;
  transition: display 0.3s ease;
  cursor: pointer;
}
.card-circle p {
  font-family: virat;
  font-size: 24px;
  margin-top: -30px;
}
.card-wrapper:hover {
  filter: grayscale(100%);
}
.card-wrapper:hover .card-circle {
  display: flex;
  flex-direction: column;
}
.card-button {
  float: right;
}
.card-wrapper div {
  float: right;
}
.button-for-landing {
  display: flex;
  width: 234px;
  height: 59px;
  padding: 0px 40px;
  justify-content: center;
  align-items: center;
  background-color: #0e1e41;
  color: #ffffff;
  font-weight: bold;
  font-family: virat;
  clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 92% 100%, 0% 100%, 0% 50%);
  cursor: pointer;
  transition: all 1s ease;
}
.button-for-landing:hover {
  background: #12337a;
  font-size: 15px;
}
.button-for-landing-two {
  background: #0e1e41;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  line-height: 100%;
  font-family: virat;
  cursor: pointer;
}
@media (max-width: 1500px) {
  .card-wrapper img {
    max-width: 420px;
  }
}

@media (max-width: 1300px) {
  .card-wrapper img {
    max-width: 350px;
  }
}

@media (max-width: 1250px) {
  .card-wrapper img {
    max-width: 275px;
  }
}

@media (max-width: 1030px) {
  .card-wrapper img {
    max-width: 225px;
    height: auto;
  }
}
@media (max-width: 768px) {
  .card-wrapper {
    max-width: 100%;
  }

  .svgg1{
    width: 20px;
  color: white;
  
  
  margin: 0;
  margin-left: 0px;
  height: 50%;
  margin-top: 12%;
  padding: 0;
  
  }
}

/* ---- Image Carasoul ---- */

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  margin-left: 30px;
}

.slider {
  height: 110px;
  overflow: hidden;
  position: relative;
}

.slider .slide-track {
  -webkit-animation: scroll 30s linear infinite;
  animation: scroll 30s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slider .slide {
  height: 100px;
  width: 250px;
}
.slider .slide {
  /* background-color: #000; */
}
.slide img {
  margin: 7%;
  width: 50%;
  height: 100px;
  max-height: 60px;
  object-fit: contain;
  /* background-color: red; */
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

/* ---- Testimonial ---- */

.testimonial-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 50px 30px;
  gap: 50px;
  max-width: 400px;
  width: 100%;
}
.testimonial-card img {
  width: 200px;
  height: auto;
}
.testimonial-name {
  font-family: virat;
  color: #f75825;
  font-size: 32px;
  font-style: normal;
  line-height: 40px;
  letter-spacing: -0.352px;
}
.testimonial-name span {
  color: #000;
  font-size: 24px;
  font-style: normal;
  line-height: 40px;
  letter-spacing: -0.264px;
}
.testimonila-disc {
  color: #000;
  font-family: virat;
  font-size: 18px;
  font-style: normal;
  line-height: 30px;
}

/* ---- Footer Styling ---- */
.footer-wrapper-mobile {
  display: none;
}
.footer-wrapper {
  width: 100%;
  position: relative;
}
.footer-wrapper .footer-background {
  width: 100%;
  height: 1130px;
}
.footer-content {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  display: flex;
  font-family: virat;
  padding: 0 50px;
  padding-top: 100px;
}
.footer-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.logo-name-div {
  position: absolute;
  top: 120%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-end;
  height: 150px;
  width: 90%;
  justify-content: space-between;
}
.haraay {
  position: absolute;
  bottom: -50%;
  right: 0;
  font-size: 22px;
  text-decoration: none;
  font-family: bwvirat;
  color: rgba(255, 255, 255, 0.1);
}
.haraay-mobile {
  font-size: 18px;
  text-decoration: none;
  font-family: bwvirat;
  color: rgba(255, 255, 255, 0.1);
}
.virat-logo-name {
  font-family: bwvirat;
  color: rgba(255, 255, 255, 0.1);
  font-size: 300px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 24.5px;
  user-select: none;
}
.footer-get-in-touch {
  position: relative;
  color: rgba(255, 255, 255, 0.85);
  font-family: bwvirat;
  font-size: 108px;
  font-style: normal;
  font-weight: 700;
  line-height: 119px;
  letter-spacing: -1.188px;
}
.footer-get-in-touch::after {
  content: "";
  position: absolute;
  bottom: -10%;
  right: 11%;
  width: 45%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.85);
}
.footer-disc {
  color: #fff;
  font-family: virat;
  font-size: 24px;
  font-style: normal;
  line-height: 40px;
  letter-spacing: -0.264px;
}
.footer-download {
  background-color: transparent;
  border: 2px solid white;
  align-self: start;
  padding: 20px 60px;
  border-radius: 100px;
  display: flex;
  gap: 5px;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
}
.footer-download-icon {
  align-self: center;
}
.footer-right {
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: self-end;
  padding-right: 100px;
  gap: 50px;
}
.footer-box {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.footercom {
  width: 420px;
  display: flex;
  padding: 20px 40px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.827);
  align-items: flex-start;
  justify-content: space-between;
}
.footercom-name {
  font-family: virat;
  font-size: 30px;
  margin-left: 20px;
}
.footercom-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.827);
  border-radius: 50%;
  align-self: self-end;
  padding: 5px;
  cursor: pointer;
}
.footercom-iamge {
  height: 100px;
  width: 100px;
}
.footercom-icon {
  font-size: 70px;
  color: rgba(255, 255, 255, 0.827);
}
.footer-social {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 350px;
}
.socialmedia-head {
  font-weight: bold;
  font-size: 24px;
}
.socialmedia-icons {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 20px;
}
.socialmedia-icons a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  color: green;
  cursor: pointer;
}
.socialmedia-icons a:first-child {
  color: blue;
}
@media (max-width: 700px) {
  .card-wrapper {
    height: 650px;
  }
  .wrapper {
    width: 100%;
  }
  .testimonial-card {
    gap: 25px;
    height: auto;
  }
  .testimonila-disc {
    font-size: 15px;
    font-family: virat;
    line-height: 20px;
    white-space: initial;
    width: 280px;
  }
  .testimonial-name {
    width: 330px;
  }
  .footer-wrapper .footer-background {
    height: 700px;
  }
  .footer-content {
    padding: 0 10px;
    padding-top: 30px;
    flex-direction: column;
    gap: 50px;
  }
  .footer-left {
    width: 100%;
    gap: 20px;
  }
  .footer-disc {
    font-size: 15px;
  }
  .footer-download {
    color: white;
    font-family: virat;
    font-size: 15px;
    padding: 15px 40px;
    border: 0.481px solid #fff;
  }
  .footer-get-in-touch {
    font-size: 55px;
  }
  .footer-get-in-touch::after {
    content: "";
    position: absolute;
    bottom: -10%;
    right: 42%;
    width: 30%;
    height: 2px;
    background-color: white;
  }
  .footer-right {
    width: 100%;
    padding: 0px;
    align-items: flex-start;
  }
  .footer-box {
    width: 100%;
    flex-direction: row;
    gap: 20px;
  }
  .footercom {
    padding: 10px 15px;
    width: 50%;
  }
  .footercom-name {
    font-size: 15px;
  }
  .footercom-icon {
    font-size: 50px;
  }
  .virat-logo-name {
    font-size: 100px;
    letter-spacing: 4px;
  }
  .logo-name-div {
    top: 120%;
  }
}
@media (max-width: 500px) {
  .footer-wrapper {
    display: none;
  }
  .footer-wrapper-mobile {
    display: flex;
  }
  .drawer-content a {
    gap: 20px;
    font-size: 25px;
  }
  .testimonial-name span {
    font-size: 20px;
  }
  .card-wrapper {
    height: 520px;
  }
  .heading-card {
    font-size: 16px;
    left: 10px;
  }
  .disc-card {
    font-size: 15px;
  }
  .testimonial-name {
    width: 260px;
  }
  .testimonial-card {
    padding: 20px;
    height: auto;
  }
  .footer-disc {
    font-size: 11px;
    line-height: 15px;
  }
  .footer-get-in-touch {
    font-size: 45px;
  }
  .footer-get-in-touch::after {
    content: "";
    position: absolute;
    bottom: 20%;
    right: 30%;
    width: 35%;
    height: 2px;
    background-color: white;
  }
  .footer-box {
    flex-direction: column;
    align-items: center;
  }
  .footercom {
    width: 270px;
  }
  .footer-wrapper .footer-background {
    height: 1030px;
  }
  .testimonial-card img {
    width: 250px;
    height: auto;
  }
  .drawer-content {
    padding: 140px 30px;
  }
}

.vlaues-card-container {
  max-width: 384px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  background: #fff;
  /* justify-content: space-between; */
}
.vlaues-card-container img {
  width: 118px;
  height: 118px;
}
.values-card-name {
  color: rgba(0, 0, 0, 0.62);
  font-size: 48px;
  font-style: normal;
  line-height: 55px;
  letter-spacing: 1px;
  font-weight: bold;
}
.values-card-disc {
  color: rgba(0, 0, 0, 0.7);
  font-family: virat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.8px;
}
@media (max-width: 900px) {
  .values-card-name {
    width: 250px;
    font-size: 30px;
  }
  .vlaues-card-container {
    padding: 40px 25px;
    gap: 0;
  }
  .values-card-disc {
    font-size: 14px;
  }
}
@media (max-width: 1500px) {
  .testimonial-card {
    gap: 30px;
    height: 450px;
  }
}
.testimonial-card {
  gap: 50px;
  height: 450px !important;
  overflow-y: scroll;
  width: 410px;
  p {
    text-align: justify;
  }
}

@media (max-width: 1030px) {
  .testimonial-card {
    max-width: 260px;
    padding: 20px 20px;
    height: 450px !important;
  }
  .testimonila-disc {
    font-size: 14px;
    line-height: 22px;
  }
  .footer-right {
    padding-right: 0;
  }
  .vlaues-card-container {
    padding: 60px 10px;
    max-width: 280px;
  }
  .values-card-name {
    font-size: 30px;
    line-height: 35px;
  }
}
@media (max-width: 800px) {
  .testimonial-card {
    max-width: 220px;
    gap: 10px;
    padding: 20px 10px;
  }
  .testimonial-card img {
    width: 150px;
  }
  .testimonila-disc {
    font-size: 12px;
    line-height: 19px;
    width: 210px;
    padding-right: 10px;
  }
  .service-back-img {
    height: 1050px;
  }
  .footer-content {
    padding: 50px 10px;
  }
  .footer-get-in-touch {
    font-size: 55px;
    line-height: 80px;
  }
  .footercom {
    width: 300px;
    padding: 10px;
  }
  .footer-disc {
    font-size: 16px;
    line-height: 26px;
  }
  .footer-download {
    padding: 15px 30px;
  }
  .footer-social {
    width: 300px;
  }
  .virat-logo-name {
    font-size: 150px;
    letter-spacing: 5px;
  }
  .haraay {
    font-size: 20px;
    bottom: -20px;
  }
  .footer-wrapper .footer-background {
    height: 950px;
  }
}
/* ---- Footer Mobile ---- */

@media (max-width: 500px) {
  .footer-wrapper-mobile {
    display: flex;
    flex-direction: column;
  }
  .card-circle {
    left: 50%;
  }
  .footer-image-mobile {
    position: relative;
    object-fit: cover;
  }
  .footer-image-mobile img {
    width: 100%;
  }
  .footer-mobile-heading {
    position: absolute;
    width: 100%;
    padding-left: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-family: bwvirat;
    font-size: 36px;
    font-style: normal;
    font-weight: bold;
    line-height: 51px;
    letter-spacing: -0.396px;
  }
  .footer-mobile-content {
    background: black;
    margin-top: -5px;
    padding: 30px 30px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .footer-mobile-text {
    color: rgba(255, 255, 255, 0.6);
    font-family: virat;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.154px;
  }
  .footer-mobile-text .footer-download span {
    color: white !important;
    font-family: virat;
    font-size: 9.619px;
    font-style: normal;
    line-height: 30.781px;
    letter-spacing: -0.106px;
  }
  .hr-div {
    background: rgba(255, 255, 255, 0.6);
    height: 0.5px;
  }
  .footer-mobile-getintouch {
    color: #fff;
    font-family: virat;
    font-size: 20px;
    font-style: normal;
    line-height: 26px;
    letter-spacing: -0.22px;
  }
  .footer-mobile-email {
    color: rgba(255, 255, 255, 0.7);
    font-family: virat;
    font-size: 14px;
    font-style: normal;
    line-height: 26px;
    letter-spacing: -0.154px;
    position: relative;
  }
  .footer-mobile-email::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 112px;
    height: 1px;
    background: rgba(255, 255, 255, 0.7);
  }
  .follow-us-on {
    color: #fff;
    font-family: virat;
    font-size: 16px;
    font-style: normal;
    line-height: 26px;
    letter-spacing: -0.176px;
  }
  .links-mobile {
    display: flex;
    gap: 20px;
  }
  .link-mobile {
    background-color: white;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: black;
  }
}
@media (max-width: 400px) {
  .heading-card {
    left: 20px;
  }
  .drawer-content a {
    font-size: 20px;
  }
}
@media (max-width: 500px) {
  .heading-card {
    left: none;
  }
  .button-for-landing {
    width: 160px;
    height: 40px;
    padding: 0px 0px;
    font-style: 15px;
  }
}
