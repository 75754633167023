/* HeroSection.module.css */
.heroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  background-color: #fef8f2;
  text-align: center;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  background-image: url("../../assets/service-back.png");
  background-size: cover;
  background-position-y: center;
}

.heroContent {
  position: relative;
  min-height: 50vh;
  width: 100%;
  margin-bottom: 2rem;
  z-index: +2;
  display: flex;
}

.titlea {
  margin: auto;
  font-family: bwvirat;
  margin-left: 30px;
  font-size: 64px;
  font-weight: 700;
  line-height: 84px;
  text-align: center;
  color: #1c2325;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 120%;
  }
}

.intro {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  z-index: -1;
}

.imagesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;

  width: 100%;
  /* max-width: 600px; */
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.teamImage {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .heroSection {
  }

  .heroContent {
    flex: 1;
    padding-right: 1rem;
  }

  .imagesContainer {
    flex: 1;
    align-items: flex-end;
  }

  .teamImage {
    margin-left: 1rem;
  }
}

/* HeroSection.module.css */

.introSection {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin: 2rem auto;
  padding: 1rem;
  background-color: #ffffff;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
}

.introTextContainer {
  padding: 2rem;
  flex: 1;
  width: 900px;
  margin: auto 0;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: justify;
  }
}

.introTitle {
  font-family: bwvirat;
  font-size: 48px;
  margin-bottom: 30px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 36px;
  }
}

.introText {font-family: virat;
  font-size: 22px;
  text-align: justify;
  width: 100%;
  line-height: 130%;
  @media (max-width: 768px) {
    font-size: 14px; text-align: justify;
  }
}

.introImageContainer {
  max-width: 500px;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.introImage {
  width: 400px;
  max-width: 400px;
  height: auto;
  height: 354px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .introSection {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  .introTextContainer {
    flex: 1;
    padding-right: 1rem;
  }

  .introImageContainer {
    flex: 1;
  }
}

.overviewTextContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: 2rem auto;
  padding: 1rem;
  background-color: #ffffff;

  .overviewTitle {
    font-family: bwvirat;
    font-size: 48px;
    margin-bottom: 30px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
  .overviewText {
    font-family: virat;
    font-size: 20px;
    text-align: center;
    /* width: 100%; */
    width: 80%;
    margin: 0 auto;
    line-height: 150%;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 130%;
      text-align: justify;
      width: 100%;
    }
  }
}
/* cards */

/* HeroSection.module.css */
.cardsSection {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  padding: 2rem;

  @media (max-width: 768px) {
    flex-direction: row;  
  justify-content: start;
   padding: 10px 10px;
    flex-wrap: nowrap; overflow-x: auto;
  }
}

.card {
  flex: 1 1 calc(33.333% - 1.5rem);
  max-width: 300px;
  min-height: 270px;
  background: linear-gradient(135deg, #fdfbfb, #ebedee);
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s;
  background: linear-gradient(
    331.87deg,
    rgba(108, 26, 0, 0.44) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  @media (max-width: 768px) {
   min-width: 250px;
  }

}

.card:hover {
  transform: translateY(-5px);
}

.cardIcon {
  width: 50px;
  height: 50px;
  margin: 0px;
  /* background-color: #ff5733; */
  /* border-radius: 50%; */

  img {
    object-fit: cover;
    z-index: +5;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cardTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1c2325;
  margin-bottom: 0.75rem;
  text-align: left;
  margin: 20px 0;
  font-family: bwvirat;
  @media (max-width: 768px) {
  font-size: 20px;
  }
}

.cardText {
  font-size: 1rem;
  color: #1c2325;
  text-align: left;
  font-family: bwviratlight;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 calc(50% - 1rem);
  }
}

@media (max-width: 480px) {
  .card {
    /* flex: 1 1 100%; */
    margin: 0 auto;
  }
}

/* approach  */

.approachsec {
  background-color: #fff3ef;
  padding: 2rem;
  margin: 10px 0;
  @media (max-width: 768px) {
    padding: 1rem;
    margin: 10px 0;
  }
  .approachmain {
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    div {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .approachleft {
      display: inline;
      flex-direction: column;

      h1 {
        font-family: bwvirat;
        font-size: 48px;
        margin-bottom: 30px;
        text-align: left;
        margin-left: 10%;
        @media (max-width: 768px) {
          text-align: center;
          font-size: 36px;
          margin-left: 0;
          margin-bottom: 10px;
        }
      }
      p {
        font-family: virat;
        font-size: 20px;
        text-align: left;
        /* width: 100%; */
        width: 80%;
        margin: 0 auto;
        line-height: 150%;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 14px;
          text-align: justify;
          padding: 0 10px;
        }
      }
    }
    .approachright {
      width: 50%;
      padding: 10px;
      @media (max-width: 768px) {
        width: 100%;
      }
      div {
        margin-bottom: 20px;
        width: 100%;
        @media (max-width: 768px) {
          margin: 20px 0;
        }
        h1 {
          font-family: bwviratlight;
          font-size: 24px;
          margin-bottom: 30px;
          text-align: left;
          @media (max-width: 768px) {
            font-size: 20px;
            width: 100%;
            margin-bottom: 10px;
          }
        }
        p {
          font-family: virat;
          font-size: 16px;
          text-align: left;
          /* width: 100%; */
          width: 80%;
          margin: 0 auto;
          line-height: 150%;
          width: 100%;

          @media (max-width: 768px) {
            font-size: 14px; text-align: justify;
          }
        }
      }
    }
  }
}

.resultsec {
  width: 100%;
  /* margin: 20px; */
  padding: 3%;
  h1 {
    font-family: bwvirat;
    font-size: 48px;
    margin-bottom: 30px;
    text-align: center;

    @media (max-width: 768px) {
      text-align: center;
      font-size: 36px;
      margin-left: 0;
    }
  }
}

.casestudy_cardr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 420px !important;
  min-height: 400px !important;
  transition: all 0.3s ease;
  border-radius: 10px;
  margin: 20px 0;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    margin: 20px 0;
  }
  .card {
    height: 100%;
  }
}

.testimo {
  width: 100%;
  /* margin: 20px; */
  padding: 3%;
  margin: 20px 0;

  h1 {
    font-family: bwvirat;
    font-size: 48px;
    margin-bottom: 30px;
    text-align: left;
    /* margin-left: 10%; */
    @media (max-width: 768px) {
      text-align: left;
      font-size: 36px;
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
  p {
    font-family: virat;
    font-size: 20px;
    text-align: left;
    /* width: 100%; */
    width: 80%;
    margin: 0 auto;
    line-height: 150%;
    width: 100%;

    @media (max-width: 768px) {
      font-size: 14px; text-align: justify; 
    }
  }
}


.services-cards{
  margin-bottom: 20px;
}